/*
 * STMNT Framework
 * 2018-2019
 *
 */

(function ($) {
  "use strict";

  var lang = $("body").data("lang");

  var STMNT = {
    debug: false,

    init: function (debug = false) {
      this.debug = debug;
      this.start();
      //this.initNav();
      //this.initHeader();
      //this.initTeaserScroll();
      //this.initContactPerson();
      //this.initSearch();
      //this.initEventForm();
      //this.scrollToTop(900);
      this.initMobileNav();
      this.slider();
      /*
      (function () {
        function init($) {
          jQuery(window).on("elementor/frontend/init", function () {
            // Wait for elementor to be initialized.
            // Then override elementorFrontend.storage.get and elementorFrontend.storage.set with empty functions,
            // to block Elementor from writing or reading anything there.
            if (
              elementorFrontend &&
              !elementorFrontend.isEditMode() &&
              !elementorFrontend.isWPPreviewMode()
            ) {
              elementorFrontend.storage.get = function () {};
              elementorFrontend.storage.set = function () {};

              if (window.sessionStorage) {
                window.sessionStorage.removeItem("elementor");
              }

              if (window.localStorage) {
                window.localStorage.removeItem("elementor");
              }
            }
          });
        }

        var waitForJQuery = setInterval(function () {
          if (typeof jQuery != "undefined") {
            init(jQuery);
            clearInterval(waitForJQuery);
          }
        }, 10);
      })();
      */

      $(".mgbutton").on("click", function () {
        $(this)
          .parentsUntil("#moove_gdpr_cookie_info_bar")
          .parent()
          .css("display", "none");
      });
    },

    slider: function () {
      $(".js--image-slider").slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
      });
    },

    start: function () {
      if (this.debug) console.log("Execute: Start");

      $(".js--mobile-nav").on("click", function () {
        $(".header-nav.mobile").slideToggle("1000");
        $(this).find("i").toggleClass("stmnt-i");
        $(this).find("i").toggleClass("stmnt-j");
      });

      $(".js--show-more-partner").on("click", function () {
        $(this).fadeOut(300, function () {
          $(this)
            .parent()
            .find(".hide-box")
            .removeClass(".hide-box")
            .fadeIn(300);
        });
      });

      if (location.hash) {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 1);
      }

      if (window.location.hash) {
        setTimeout(function () {
          let hash = window.location.hash;

          let dest = 0;
          if (
            $(hash).offset().top >
            $(document).height() - $(window).height()
          ) {
            dest = $(document).height() - $(window).height();
          } else {
            dest = $(hash).offset().top;
          }

          $("html,body").animate({ scrollTop: dest - 100 }, 1000, "swing");
        }, 1000);
      }
    },

    initNav: function () {},

    initHeader: function () {},

    isElementorMode: function () {
      $.urlParam = function (name) {
        var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
          window.location.href
        );
        if (results == null) {
          return null;
        } else {
          return true || false;
        }
      };
      return $.urlParam("elementor-preview");
    },

    initMobileNav: function () {
      $(".js--mobile-nav").on("click touch", function () {
        $(this).find(".js--hamburger-btn").toggleClass("open");
        $("html,body").toggleClass("is-mobile-menu-active");
        $(".js--mobile-menu-close").toggleClass("open");
      });

      $(".js--mobile-menu-close").on("click touch", function () {
        $(".js--mobile-nav").trigger("click");
      });

      $(".js--mobile-sub-menu li.menu-item-has-children>a").on(
        "click touch",
        function (e) {
          e.preventDefault();

          var submenu = $(this).parent().find(">ul.sub-menu");

          if (submenu.length) {
            submenu.slideToggle("fast");
          }

          return;
        }
      );

      $(".js--mobile-nav-close").on("click touch", function () {
        $("html,body").removeClass("is-mobile-menu-active");
      });
    },
  };

  STMNT.init(true);
})(jQuery);
